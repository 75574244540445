@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body * {
    font-family: 'Montserrat', sans-serif;
}

.swal-text, .swal-footer {
    text-align: center !important;
}
